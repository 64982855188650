import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";
import { Button } from "../../components/Button";
import { useActivateGame, useCompleteGame, useDeleteGame, useGame } from "../../lib/data/game";
import { NotFoundPage } from "./NotFound";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { StatFeed } from "../stats/StatFeed";
import { ErrorContent } from "./ErrorPage";
import { LoadingSpinner } from "../../components/Spinner";
import { GameStatsTable } from "../stats/GameStatsTable";
import { FaChevronLeft, FaEllipsisH } from "react-icons/fa";
import { PiShareBold } from "react-icons/pi";
import { GrCheckmark } from "react-icons/gr";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { ContentContainer } from "../../components/ContentContainer";
import { usePaste } from "../../lib/utils/pasteboard";
import { useState } from "react";
import { GameContent } from "../games/GameContent";
import { IoIosStats } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { useGamePermissions } from "../../lib/permissions";
import { GameBadges } from "../../components/Badges";
import { useMenuOverrides } from "../../lib/utils/useMenuOverrides";

export function GamePage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage returnRoute="/dashboard" />;
  return (
    <PageContainer>
      <GamePageContent gameId={id} />
    </PageContainer>
  );
}

const TABS = ["stats", "feed"];
function GamePageContent({ gameId }: { gameId: string }) {
  // handle tab param in url
  const [param] = useSearchParams();
  const tabParam = param.get("tab") ?? "";
  const defaultTab = TABS.includes(tabParam) ? tabParam : "stats";
  const { status, data: game } = useGame(gameId);
  const permissions = useGamePermissions(gameId);
  const { mutateAsync: activateGame } = useActivateGame();
  const onActivate = async () => {
    try {
      await activateGame(gameId);
    } catch (e) {
      console.error(`failed to start game, ${e}`);
    }
  };

  if (status === "error") {
    return <ErrorContent message="Unable to find game. Please try again later." />;
  } else if (status === "pending") {
    return <LoadingSpinner />;
  }

  const tabsConfig: TabsConfig = {
    ariaLabel: "Game tabs list",
    defaultValue: defaultTab,
    tabs: [
      {
        value: "stats",
        content: <GameStatsTable gameId={gameId} />,
      },
      {
        value: "feed",
        content: <StatFeed gameId={gameId} />,
      },
    ],
    bgColor: "white",
  };

  return (
    <div className="">
      <ContentContainer className="flex max-w-full flex-col items-center p-0 pb-2">
        <div className="flex w-full justify-center bg-brown">
          <div className="min-w-sm max-auto flex max-w-screen-lg flex-1 items-center justify-between p-2.5">
            {game.collectionId && (
              <Link to={`/session/${game.collectionId}`}>
                <FaChevronLeft size="24" fill="white" />
              </Link>
            )}
            <span className="flex items-center gap-2">
              <span className="header-banner text-white">{game.title}</span>
              {game.gameState === "active" && (
                <div className="h-2 w-2 animate-pulse rounded-full bg-emerald-500"></div>
              )}
            </span>
            <GameEllipsis gameId={gameId} hideOptions={["showStats"]} className="fill-white" />
          </div>
        </div>
        <GameContent game={game} headerMode={true} size={"md"} />
        <GameBadges className="w-full max-w-lg" gameId={gameId} />
        {permissions.isCurrentUserGameHost && (
          <div className="flex w-full flex-col justify-center gap-3 px-2.5 pb-6 pt-4 md:flex-row">
            {game.gameState === "pending" && (
              <Button
                className="w-full"
                intent={"yellow"}
                label="start game"
                onClick={onActivate}
              />
            )}
            {["active", "complete"].includes(game.gameState) && (
              <Link to={`/game/${gameId}/stats`} className="flex w-full justify-center text-center">
                <Button className="w-full" intent={"green"} label="stat keeping mode" />
              </Link>
            )}
          </div>
        )}
      </ContentContainer>
      <FolderTabs tabsConfig={tabsConfig} />
    </div>
  );
}

export type GameEllipsisOption = "shareGame" | "showStats" | "finishGame" | "deleteGame";

// TODO: fix props
export function GameEllipsis({
  gameId,
  hideOptions = [],
  className,
}: {
  gameId: string;
  hideOptions?: GameEllipsisOption[];
  className?: string;
}) {
  const navigate = useNavigate();
  const { menuOverride, triggerOverride } = useMenuOverrides();
  const { isCurrentUserGameHost } = useGamePermissions(gameId);
  const { data: game } = useGame(gameId);
  const [confirmComplete, setConfirmComplete] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const copyToClipboard = usePaste();
  const { mutateAsync: completeGame, isPending: isPendingComplete } = useCompleteGame();
  const { mutateAsync: deleteGame, isPending: isPendingDelete } = useDeleteGame();
  const message = `Check out this game on SomeDubs! Link here: ${window.location.href}`;
  const onShare = () => {
    copyToClipboard(message);
  };

  const onConfirmComplete = async () => {
    try {
      await completeGame(gameId);
      setConfirmComplete(false);
    } catch (e) {
      console.error(e);
    }
  };
  const onConfirmDelete = async () => {
    try {
      await deleteGame(gameId);
      setConfirmComplete(false);
      if (game?.collectionId) {
        navigate(`/session/${game.collectionId}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const dialogs = (
    <>
      <ConfirmationDialog
        type="confirm"
        open={confirmComplete}
        onOpenChange={setConfirmComplete}
        action={onConfirmComplete}
        isLoading={isPendingComplete}
        cancelText="No. Not yet!"
        confirmText="Yes. Finish Game!"
      />
      <ConfirmationDialog
        type="error"
        open={confirmDelete}
        onOpenChange={setConfirmDelete}
        action={onConfirmDelete}
        isLoading={isPendingDelete}
        cancelText="No. Not yet!"
        confirmText="Yes. Delete this game!"
      />
    </>
  );
  return (
    <DropdownMenu {...menuOverride}>
      {dialogs}
      <DropdownMenuTrigger {...triggerOverride}>
        <FaEllipsisH size="30" className={className} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white-custom">
        {!hideOptions.includes("shareGame") && (
          <DropdownMenuItem onClick={onShare} className="cursor-pointer">
            <PiShareBold className="h-[20px] w-[36px] pb-[3px] pr-2" /> Share Game
          </DropdownMenuItem>
        )}
        {!hideOptions.includes("showStats") && (
          <DropdownMenuItem
            onClick={() => navigate(`/game/${gameId}?tab=stat`)}
            className="cursor-pointer"
          >
            <IoIosStats className="h-[20px] w-[36px] pb-[3px] pr-2" /> Show Stats
          </DropdownMenuItem>
        )}
        {!hideOptions.includes("finishGame") &&
          (game?.gameState === "active" || game?.gameState === "pending") &&
          isCurrentUserGameHost && (
            <DropdownMenuItem onClick={() => setConfirmComplete(true)}>
              <GrCheckmark className="h-[20px] w-[36px] pb-[3px] pr-2" />
              Finish Game
            </DropdownMenuItem>
          )}
        {!hideOptions.includes("deleteGame") && isCurrentUserGameHost && (
          <DropdownMenuItem onClick={() => setConfirmDelete(true)}>
            <MdDeleteOutline className="h-[20px] w-[36px] pb-[3px] pr-2" />
            Delete Game
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
