import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { classNames } from "../lib/utils/classNames";
import { LoadingSpinner } from "./Spinner";

// TODO: change these colors to style guide
const buttonVariants = cva(
  "flex items-center justify-center gap-2 text-nowrap px-4 py-2 md:max-w-xs",
  {
    variants: {
      intent: {
        inline: ["inline-flex", "text-green", "font-medium", "hover:underline", "focus:underline"],
        outline: [
          "bg-white",
          "hover:bg-grey-light",
          "active:bg-grey-light",
          "text-black",
          "border",
          "border-black",
        ],
        circle: [
          "rounded-full",
          "bg-primary",
          "text-white",
          "h-[50px]",
          "w-[50px]",
          "text-md",
          "text-center",
        ],
        primary: ["bg-blue-500", "hover:bg-blue-600", "text-white"],
        danger: ["bg-error", "text-white"],
        yellow: [
          "bg-yellow",
          "active:bg-yellow-light",
          "hover:bg-yellow-light",
          "border",
          "border-black",
        ],
        green: [
          "bg-green",
          "active:bg-green-dark",
          "hover:bg-green-dark",
          "border",
          "border-black",
          "text-white",
        ],
        light: ["bg-gray-100", "hover:bg-gray-300", "text-grey-dark"],
        disabled: ["bg-grey", "text-grey-dark", "cursor-not-allowed"],
      },
      size: {
        inline: ["text-md", "p-0"],
        xs: ["text-xs", "py-1.5", "px-4"],
        sm: ["text-sm", "py-2", "px-4"],
        md: ["text-md", "py-2", "px-8"],
        lg: ["text-lg", "py-4", "px-8"],
        xl: ["text-xl", "py-5", "px-10"],
      },
    },
    defaultVariants: {
      intent: "outline",
      size: "md",
    },
  },
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  label: string | JSX.Element;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, label, intent, size, isLoading = false, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          buttonVariants({ intent: props.disabled ? "disabled" : intent, size }),
          className,
        )}
        {...props}
      >
        {label}
        {isLoading && (
          <div>
            <LoadingSpinner className="h-[24px] w-[24px] border-4" />
          </div>
        )}
      </button>
    );
  },
);
Button.displayName = "Button";

const iconButtonVariants = cva("transition ease-in-out", {
  variants: {
    intent: {
      iconOnly: ["p-0", "relative"],
      iconTab: [
        "p-0",
        "relative",
        "h-8",
        "w-11",
        "flex",
        "items-center",
        "justify-center",
        "border-b-2 border-b-transparent",
        "data-[active=true]:border-b-2 data-[active=true]:border-b-green",
      ],
      statFeed: [
        "border",
        "border-black",
        "h-11",
        "w-11",
        "flex",
        "items-center",
        "justify-center",
        "hover:bg-green-light",
        "active:bg-green-light",
        "relative",
      ],
      stats: [
        "flex",
        "flex-col",
        "justify-center",
        "items-center",
        "p-1",
        "flex-1",
        "gap-1",
        "rounded-lg",
        "border-black",
        "border",
        "text-md",
        "font-medium",
        "leading-none",
      ],
    },
    bgColor: {
      yellow: ["bg-yellow", "hover:bg-yellow-light", "active:bg-yellow-light"],
      green: ["bg-green-stat", "hover:bg-green-statHover", "active:bg-green-statActive"],
      red: ["bg-red-stat", "hover:bg-red-statHover", "active:bg-red-statActive"],
    },
    state: {
      active: [],
      disabled: ["text-grey-dark", "opacity-50", "cursor-not-allowed"],
    },
  },
  defaultVariants: {
    intent: "iconOnly",
  },
});

export type IconButtonCva = VariantProps<typeof iconButtonVariants>;

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  label?: string;
  showLabel?: boolean;
  stacked?: boolean;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, label, intent, bgColor, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          iconButtonVariants({
            intent: intent,
            bgColor: bgColor,
            state: props.disabled ? "disabled" : "active",
          }),
          className,
        )}
        {...props}
      >
        {children}
        <span
          className={classNames(
            "",
            intent === "iconOnly" || intent === "iconTab" || intent === "statFeed" ? "sr-only" : "",
          )}
        >
          {label}
        </span>
      </button>
    );
  },
);
Button.displayName = "IconButton";

export { Button, IconButton };
